.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/* Your existing CSS */

/* Style for the fixed button */
.fixed-button {
  position: fixed;
  bottom: 20px; /* Adjust the bottom position as needed */
  right: 20px; /* Adjust the right position as needed */
  z-index: 1000; /* Ensure it's on top of other elements */
}

.fixed-button button {
  padding: 15px; /* Adjust the padding to change the button size */
  font-size: 18px; /* Adjust the font size to change the button text size */
  background-color: #297fb9; /* WhatsApp green color */
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.fixed-button button:hover {
  background-color: #128C7E; /* Darker green color on hover */
}

/* Your existing styles for other elements */
/* styles.css */

/* Container Styling */
.home-container {
  padding: 20px;
}

/* Modal Container Styling */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

/* Modal Content Styling */
.modal-content {
  background: #fff; /* White background for modal content */
  padding: 20px;
  border-radius: 8px;
}

/* Button Styling */
.logout-button,
.unsubscribe-button,
.change-password-button {
  margin-right: 10px;
}

/* Icon Styling */
.icon {
  font-size: 20px;
  margin-right: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  /* Adjust styles for smaller screens */
  .home-container {
    padding: 10px;
  }
  .modal-content {
    padding: 10px;
  }
}
