
.slideshow {
    position: relative;
    width: auto; /* Set a fixed width for the cube container */
    height: 500px; /* Set a fixed height for the cube container */

   }
  
  .cube {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;   

   }
  
  .slideshow-card {
    position: absolute;
    width: 100%;
    height: auto;
    opacity: 0;
     transition: opacity 0.8s ease-in-out;   
    border-radius: 8px;
 
  }
  
  .slideshow-card.active {
    opacity: 1;
    z-index: 1;
  }
  
  .slideshow-image {    
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
    width: 100%;
    height: 100%;    

  }
  
  .slideshow-description {
    background-color: rgba(0, 0, 0, 0.815);
    padding: 10px; /* Adjust padding for better spacing */
    color: #fff;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.slideshow-description p {
    margin: 0;
    color: #ffffff;
    font-size: 14px;
    line-height: 1.4;
}


@media only screen and (max-width: 600px) {
  /* Adjust styles for smaller screens */

  /* Slideshow container height */
  .slideshow {
      height: 300px;
      width:100% ;
  }

  /* Slideshow card border radius */
  .slideshow-card {
      border-radius: 0;
  }

  /* Slideshow description padding */
  .slideshow-description {
      padding: 5px;
  }

  /* Slideshow description font size */
  .slideshow-description p {
      font-size: 12px;
  }
}