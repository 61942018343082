/* Article.css */
.container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .article-container {
    padding: 20px;
  }
  
  .article {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .article-image {
    width: 100%;
    height: auto;
  }
  
  .article-content {background-color: #ddd;
    padding: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .article-author {
    font-size: 14px;
    color: #666;
  }
  
  .article-description {
    font-size: 16px;
    line-height: 1.5;
  }
  .article-title {
    margin-bottom: 10px; /* Optional: Add margin for better spacing */
  }