.product-detailse {
  background-color: white;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  width: 60%; /* Adjust the width as needed */
} 

.product-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.product-details {
  flex-grow: 1;
}

.product-info {
  margin-top: 20px;
}

.product-image {
  max-width: 100%;
}

.nametxt {
  font-size: 30px;
  /* Adjust the font size as needed */
  font-weight: bold;
  color: #333;
  /* Choose an appropriate text color */
  margin-bottom: 8px;
  /* Add margin at the bottom for spacing */
}

@media (min-width: 600px) {
  .product-container {
    flex-direction: row;
  }

  .product-image {
    margin-left: 20px;
    margin-right: 0;
  }
}