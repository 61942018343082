/* App.css */
.home-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .modalContainer {
    display: none;
  }
  
  p {
    margin: 10px 0;
    line-height: 1.5;
  }
  
  /* Buttons */
  button {
    margin-top: 10px;
    cursor: pointer;
  }
  
  .unsubscribe-button {
    color: #fff;
    background-color: #ff5555;
  }
  
  .change-password-button {
    color: #fff;
    background-color: #4285f4;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .home-container {
      width: 100%;
      padding: 10px;
    }
  }
  