/* Center the image within its column */
.col-md-4 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Conectivitysectionn {
    max-width: 1500px;
    margin: 50px auto;
    padding: 20px;
    background-color: #000000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .automationtxt{
    color: black !important;
  }