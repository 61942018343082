
body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .app-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  h2 {
    color: #333;
  }
  
  .navlist {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .btn {
    margin: 0 15px;
  }
  
  button {
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: #000000;
  }
  
  .continue-btn {
    margin-top: 20px;
    padding: 15px 30px;
    background-color: #000000;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .continue-btn:hover {
    background-color: #2980b9;
  }
  