.titlem {
    font-size: 24px; /* Adjust the font size for the heading */
    color: #ffffff; /* A deep, elegant color for the text */
    margin-bottom: 10px; /* Add a bit of space below the heading */
  }

.titleh {
    text-align: center;
    margin-top: 20px;
    color: #3498db;
    font-size: 2em;
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}


.bottom-line {
    border-bottom: 1px solid #0e0d0d;
    padding-right: 25%;
    padding-left: 25%;
    width: 50%;
    height: 50%;
}
.sectionnS {
    color: #333;
    background-color: #f9f9f9;
    max-width: 800px;
    margin: 40px auto;
    padding: 50px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    font-family: 'Poppins', Arial, sans-serif; /* A modern and stylish font-family */
    font-size: 18px;
    line-height: 1.8;
  }
  
  .sectionnS a {
    color: #4CAF50; /* A fresh and vibrant link color */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .sectionnS a:hover {
    color: #45a049; /* Darker link color on hover for a subtle effect */
  }
  
  .sectionnS h1 {
    color: #2c3e50; /* A deep, elegant color for headings */
  }
  
  .sectionnS p {
    color: #555;
  }
  
  .sectionnS img {
    border-radius: 10px; /* Add a slight border-radius to images */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 100%; /* Ensure images don't overflow their container */
  }
  /* Default styles for larger screens */
.sectionnS {
    font-size: 18px;
  }
  
  /* Responsive font size adjustments for smaller screens */
  @media only screen and (max-width: 600px) {
    .sectionnS {
      font-size: 16px;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .sectionnS {
      font-size: 14px;
    }
  }
  