/* modal用css */
.modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal__content {
  background-color: #fff;
  padding: 20px 50px;
}

/* Toast用css */

.toastContainer .toast {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  padding: 10px;
  z-index: 100;
  display: block;
}

.toastContainer .toast__content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 8px gray;
  padding: 12px 50px;
}

.toastContainer .toast__button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  font-size: 30px;
}

.toastContainer .toast__button:active {
  opacity: 0.3;
}

/* Popup用css */
.imgContainer {
  position: relative;
}
.msg {
  position: absolute;
  top:10%;
  left: 55%;
  z-index: 100;
  border: solid 1px ;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
}


/* 共通css */
.btn {
  margin-top: 10px;
  background-color: pink;
  border:none;
  border-radius: 5px;
}
.link-css {
  margin-top: 10px;
}
