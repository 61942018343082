/* SignUp.css */

/* Container styles */
.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f8f8f8;
  }
  
  /* Form styles */
  .signup-form {
    max-width: 400px;
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .signup-form label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .signup-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .signup-form p {
    margin-bottom: 15px;
    font-size: 14px;
    color: #555;
  }
  
  /* Button styles */
  .signup-btn {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #03a9f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .signup-btn:hover {
    background-color: #0288d1;
  }
  
  /* Link styles */
  .login-link {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .login-link a {
    color: #03a9f4;
    text-decoration: none;
  }
  
  .login-link a:hover {
    text-decoration: underline;
  }
  .login-btn:hover{
    color: #03a9f4;

  }
  .error-message {
    color: #ff0000; /* Red text color for error messages */
    font-size: 14px;
    margin-top: -10px; /* Adjust the margin based on your design preferences */
  }