/* Solution.css */

/* Container for the solution cards */
.solution-container {
    display: flex;
    justify-content: space-between;
    margin-left: 150px;
    margin-right: 150px;
    margin-bottom: 150px;
    margin-top: 50px;
}

/* Individual solution card styling */
.solution-card {
    width: 250px;
    height: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease;
    background-size: cover;
    background-position: center;
    color: #fff;
    overflow: hidden;
    position: relative;
}

/* Hover effect for the solution card */
.solution-card:hover {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.5);
}

/* Content inside the solution card */
.card-content {
    transition: transform 0.3s ease;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    padding: 15px;
    transform: translateY(100%);
}

/* Hover effect for the content inside the solution card */
.solution-card:hover .card-content {
    transform: translateY(0);
}

/* Title styling */
.card-title {
    font-size: 18px;
    margin-bottom: 10px;
    color: rgb(97, 218, 251, 255);
}

/* Description styling */
.card-description {
    font-size: 14px;
    color: #ffffff;
}

/* Apply margin only to non-last cards */
.solution-card:not(:last-child) {
    margin-right: 20px;
}

.solution-title {
    font-size: 2em;
    text-align: center;
    color: #333;
    margin-bottom: 30px;
    text-transform: uppercase;
}

/* Update your .friendly-message styles */
.friendly-message {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    width: 250px;
    color: #fff;
    font-size: 24px;
    line-height: 1.5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Optionally, add transition for a smoother hover effect */
.friendly-message:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    color: rgb(97, 218, 251, 255);
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .solution-container {
        flex-direction: column; /* Change direction to vertical for small screens */
        align-items: center; /* Center align items */
        margin-left: 20px; /* Adjust margin for better spacing */
        margin-right: 20px;
    }

    .solution-card {
        margin-bottom: 20px; /* Add margin between cards in vertical layout */
    }
}
