.container {background-color: #ccc;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .user-info {
    border: 1px solid #000000;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .user-info p {
    margin: 5px 0;
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  /* Optional: Add styles for total quantity */
  .total-quantity {
    font-weight: bold;
    margin-top: 20px;
  }
  

  .info-container {
    color: #0a0a0a; /* Dark Grayish Blue Text Color */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;  
  
  }
  
  /* Heading styling */
  .info-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #3498db; /* Blue Heading Color */
  }