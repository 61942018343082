/* ShoppingCart.css */

.shopping-cart-container {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
}

.cart-summary {
  display: flex;
  flex-direction: column; /* Adjust layout for smaller screens */
  align-items: center;
  margin-top: 20px;
}

.total {
  font-size: 1.2em;
  font-weight: bold;
}

.proceed-to-checkout-btn {
  background-color: #28a745;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.proceed-to-checkout-btn:hover {
  background-color: #218838;
}

.cart-item {
  display: flex;
  flex-direction: column; /* Adjust layout for smaller screens */
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.cart-item-image {
  max-width: 100%; /* Adjust image size to fit smaller screens */
  max-height: 200px; /* Set a maximum height for the image */
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-name {
  font-weight: bold;
}

.cart-item-quantity {
  margin-top: 5px;
}

.cart-item-price {
  color: #007bff;
  font-size: 1.2em;
}

.cart-item-actions {
  display: flex;
  align-items: center;
}

.update-quantity-btn,
.remove-item-btn {
  background-color: #dc3545;
  color: #fff;
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.update-quantity-btn:hover,
.remove-item-btn:hover {
  background-color: #c82333;
}

/* Responsive styles */
@media (max-width: 768px) {
  .cart-item-actions {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
    align-items: center;
  }
}
