.latest-news-container {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 5%;
    background-color: #f4f4f4;
    color: black;
  }
  
  header {
    text-align: center;
  }
  
  .NewsB {
    padding: 50px;
    width: 100%;
    height: 56vh;
    background-image: url('images/cover.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 1pc;
  }
  
  .friendly-coverNews {
    background-color: rgba(0, 0, 0, 0.8);
    padding-right: 15px;
    padding-left: 5%;
    border-radius: 10px;
    width: 35%;
    margin: 0;
    color: #fff;
    font-size: 50px;
    line-height: 1.5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .friendly-coverNews:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    color: rgb(41, 127, 185);
  }
  /* Media queries for tablets and smaller screens */
  @media (max-width: 768px) {
    .NewsB {
      padding: 50px;
      width: auto;
      height: 90%;
      background-image: url('images/lamb.jpg');
      background-size: cover;
      min-height: 20vh;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      border-radius: 1pc;
    }
  
    .friendly-coverNews {
      font-size: 25px;
      width: max-content;
    }
  
    .grid-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
    }
  
  }
  
  /* Media queries for mobile phones */
  @media only screen and (max-width: 480px) {
    .NewsB {
      padding: 30px;
      /* Adjusted padding for smaller screens */
    }
  
    .friendly-coverNews {
      font-size: 25px;
      width: max-content;    /* Adjusted font size for smaller screens */
    }
  
    /* Additional styles for mobile phones can go here */
   
  }
  
   .news-items-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Three equal-width columns */
    gap: 20px;
    /* Adjust the gap between news items as needed */
    margin: 0 auto;
   
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
     gap: 20px;
     justify-content: center;
    margin: 0 auto 0 50px;
   }
  
  .news-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .news-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
  
  .news-content {
    padding: 16px;
  }
  
  .news-description {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .news-date {
    font-size: 14px;
    color: #888;
  }
  
  /* Add hover effect */
  .news-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transition: box-shadow 0.1s ease-out, transform 0.1s ease-out;
  }
  
  
  @media (max-width: 768px) {
    .NewsB {
      padding: 50px;
      width: auto;
      height: 90%;
      background-image: url('images/lamb.jpg');
      background-size: cover;
      min-height: 20vh;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      border-radius: 1pc;
    }
  
    .friendly-coverNews {
      font-size: 25px;
      width: max-content;
    }
  
    .grid-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
     
      justify-content: center;
       margin: 0 auto 0 50px;
   
    }
  
    /* Additional styles for smaller screens can go here */
  }
  @media (max-width: 768px) {
    .NewsB {
      padding: 50px;
      width: auto;
      height: 90%;
      background-image: url('images/lamb.jpg');
      background-size: cover;
      min-height: 20vh;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      border-radius: 1pc;
      align-self: center;
      align-items: center;
      align-content: center;
    }
  
    .friendly-coverNews {
      font-size: 25px;
      width: max-content;
    }
  
    /* Additional styles for smaller screens can go here */
  }
  
  /* Media queries for mobile phones */
  @media only screen and (max-width: 480px) {
    .NewsB {
      padding: 50px; /* Adjusted padding for a more subtle effect on smaller screens */
          width: auto;
          height: 90%;
          background-image: url('images/cover.png');
          background-size: cover;
          min-height: 20vh; /* Changed to min-height for better responsiveness, adjust as needed */
  
          background-position: center;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: flex-start; /* Adjusted to start from the left */
          text-align: left; /* Align text to the left */
          border-radius: 1pc;
    }
  
    .friendly-coverNews {
      font-size: 25px;
      width: max-content;
    }
  
    .grid-container,
    .news-items-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
       gap: 20px;
   
      margin: 0 auto 0 50px;
  
      justify-content: center;
 
  
    }
   
  }