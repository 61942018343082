.bas {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added box shadow for a card-like appearance */
    background-color: #fff;
    border-radius: 8px; /* Added border-radius for rounded corners */
    transition: box-shadow 0.3s ease-in-out; /* Added a smooth transition for box shadow */
  }
  
  .bas:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Added a slightly larger shadow on hover */
  }
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-type {
    margin-top: 20px;
  }
  
  h2, h3 {
    margin-bottom: 10px;
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .button-container {
    display: flex;
    flex-direction: column; /* Change to column for smaller screens */
  }
  
  .button-container button {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .registration-section {
    margin-top: 20px;
    text-align: center;
  }
  
  /* Customize the Google sign-in image as needed */
  .login-type img {
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    height: auto;
  }
  
  /* Add any additional styling based on your design preferences */
  
  /* Media query for smaller screens */
  @media screen and (max-width: 600px) {
    .button-container {
      flex-direction: column;
    }
  }
  .titel{
    color: #03a9f4;

  }