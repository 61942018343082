.contact-us-container {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 5%;
    background-color: #f4f4f4;
    color: black;
  }
 
  header {
    text-align: center;
  }
  
  .contact {
    color: black;
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #bdb9b9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .col-md-6 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .col-md-6 img {
    max-width: 100%; /* Ensure the image doesn't exceed the width of its container */
    height: auto; /* Maintain the image's aspect ratio */
  }
  label {
    display: block;
    margin: 15px 0 5px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button {
    background-color: #333;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
   .contact-info a:hover {
    color: rgb(97, 218, 251);
  }
  
   .contact-info {
    margin-top: 20px;
    color: black;
  }
  
  .contact-info p {
    margin: 0;
    /* Remove default margins for better spacing */
  }
  
  .contact-link {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    color: #333;
  }
  
  .follow-us {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }
  
  .icon-link {
    text-decoration: none;
    color: #333;
  }
  
   
  
  .social-icons {
    display: flex;
    gap: 10px;
  }
  
   
  
  .icon-link:hover {
    color: rgb(97, 218, 251);
  }
  
  /* button {
    background-color: #333;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Add a transition for smooth effect 
  
    
  } */
  
  /* button:hover {
    color: rgb(97, 218, 251);
  } */
  .button {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    width: 250px;
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .btnh:hover {
    transform: scale(1.05);
    /* Scale up the message on hover for a subtle effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    /* Increase box shadow on hover */
    color: rgb(97, 218, 251);
  }
  
 

.friendly-cover:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    color: rgb(97, 218, 251);
}

.contactn.with-backgroundC {
    padding: 50px; /* Adjusted padding for a more subtle effect on smaller screens */
    width: 100%;
    background-image: url('images/artcontact.jpg');
    background-size: cover;
    background-position: center;
    text-align: center; /* Center the content within the section */
    border-radius: 1pc;

  }
  .friendly-cover { 
    background-color: rgba(0, 0, 0, 0.8);
    padding-right: 15px; /* Changed from 15% to a fixed value for responsiveness */
    padding-left: 5%;
    border-radius: 10px;
    width: 35%; /* Adjusted the width to your preference */
    margin: 0; /* Removed auto margin to align to the left */
    color: #fff;
    font-size: 24px;
    line-height: 1.5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 768px) {
    .contactn.with-backgroundC {
      padding: 100px;
    }
  
    .friendly-cover {
      font-size: 50px;
    }
  }
  