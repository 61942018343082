/* Reset default margin and padding */
body, h1, h2, p, ul {
    padding: 0;
  }
  .Supportcontainer {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 5%;
    background-color: #f4f4f4;
    color: black;
  }
 
/* styles.css */
.support-text {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center; /* Center the text horizontally */
  line-height: 1.5;    /* Optional: Adjust line height for vertical centering */
  margin: 0;           /* Optional: Remove default margin */
  padding: 0;          /* Optional: Remove default padding */
}

  body {
    font-family: Arial, sans-serif;
    color: black;
  }
  
  /* Header styles */
  header {
    text-align: center;
  }
  
  /* Form styles */
  label {
    display: block;
    margin: 15px 0 5px;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button {
    background-color: #333;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: rgb(97, 218, 251);
    color: #fff;
  }
  
  /* Contact Info styles */
  .contact-info {
    margin-top: 20px;
    color: black;
  }
  
  .contact-info p {
    margin: 0;
  }
  
  .contact-link {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    color: #333;
  }
  
  .follow-us {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }
  
  .icon-link {
    text-decoration: none;
    color: #333;
  }
  
  .icon-link:hover {
    color: rgb(97, 218, 251);
  }
  
  /* Button styles */
  .button {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    width: 250px;
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .button:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    color: rgb(97, 218, 251);
  }
  
  /* Section styles */
  .sectionn {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #bdb9b9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  

  
  /* Friendly Cover styles */
  .friendly-coverSupport {
    background-color: rgba(0, 0, 0, 0.8);
    padding-right: 15px; /* Changed from 15% to a fixed value for responsiveness */
    padding-left: 5%;
    border-radius: 10px;
    width: 35%; /* Adjusted the width to your preference */
    margin: 0; /* Removed auto margin to align to the left */
    color: #fff;
    font-size: 50px;
    line-height: 1.5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  
  .friendly-coverSupport:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    color: rgb(97, 218, 251);
} 

  .supportB {
    padding: 50px;
    width: 100%;
    height: 56vh;
    background-image: url('images/lamb.jpg');
    background-size: cover;
     background-repeat: no-repeat;
    display: flex;
    align-items: center;
     text-align: center; /* Align text to the center */
    border-radius: 1pc;
  }
  

 


  @media (max-width: 768px) {
    .supportB {
        padding: 50px; /* Adjusted padding for a more subtle effect on smaller screens */
        width: auto;
        height: 90%;
        background-image: url('images/lamb.jpg');
        background-size: cover;
        min-height: 20vh; /* Changed to min-height for better responsiveness, adjust as needed */

        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: flex-start; /* Adjusted to start from the left */
        text-align: left; /* Align text to the left */
        border-radius: 1pc;
    }
 
  }
  @media only screen and (max-width: 768px) {
    .friendly-coverSupport {
      font-size: 25px; /* Adjusted font size for smaller screens */
      width: max-content;
    }
  }

  .SupportG-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Three equal-width columns */
    gap: 20px; /* Adjust the gap between items as needed */
  }
  
  /* Style for each grid item */
  .SupportG-item {
    background-color: #f2f2f2;
    padding: 20px;
    text-align: center; 
  }

  

  @media (max-width: 768px) {

  
    
    .SupportG-container{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
    }
    .SupportG-item {
      background-color: #f2f2f2;
      padding: 20px;
      text-align: center; 
    }
    .test{
      background-color: rgb(160, 160, 160);
     }
     .testt{
      background-color: rgb(126, 125, 125);
     }
     .testtt{
      background-color: rgb(75, 75, 75);
     }
     .coloreP{
      color: black;
     }
  }