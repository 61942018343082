.language-button-container {
    position: relative;
    display: inline-block;
  }
  .buttontr {
    background-color: black;
    color: white; /* Text color, you can adjust this as needed */
  }
  .tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }
  
  .tooltip button {
    margin-bottom: 5px;
  }
  