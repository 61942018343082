.titlem {
    padding-left: 5%;
}

.titleh {
    text-align: center;
    margin-top: 20px;
    color: #3498db;
    font-size: 2em;
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}


.bottom-line {
    border-bottom: 1px solid #0e0d0d;
    margin-top: 20px;
    padding-right: 25%;
    padding-left: 25%;
    width: 50%;
    height: 50%;
}