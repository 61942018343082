 
.contact-us-solution {
    font-family: Arial, sans-serif;
    margin: 0;
    padding-top: 5%;
    background-color: #f4f4f4;
    color: black;
  }
.background-Sol {    padding-top: 5%;

    background-image: url('images/blueback.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 40vh;
}

.solution-buttons {
  flex-wrap: wrap; /* Allow buttons to wrap to the next line on small screens */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.solution-button {
  margin: 0 10px;
  padding: 15px 30px;
  font-size: 18px;
  background-color: #000000; /* Update to your desired color */
  color: rgb(253, 253, 253);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.solution-button:hover {
  color: rgb(41, 127, 185);
  background-color: #68686800; /* Change color on hover if desired */
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0);
}


/* Add specific styles for each button */
 
  

 /* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .solution-button {
    width: 100%; /* Make buttons full width on small screens */
    margin: 0 0 30px 0; /* Adjusted margin for better spacing on small screens */
  }
  .solution-button {
    margin: 0 10px;
    padding: 5px 15px;
    font-size: 18px;
    background-color: #000000; /* Update to your desired color */
    color: #297fb9;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  .background-Sol {    

    background-image: url('images/blueback.jpg');
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    height: 30vh;
}
.solution-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
}