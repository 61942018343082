.titlem {
    padding-left: 5%;
}

.titleh {
    text-align: center;
    margin-top: 20px;
     color: #3498db;
    font-size: 2em;
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}


.bottom-line {
    border-bottom: 1px solid #0e0d0d;
     padding-right: 25%;
    padding-left: 25%;
    width: 50%;
    height: 50%;
}
.automationtxt {
    max-width: 1000px;
    margin: 50px auto;
    padding: 20px;
    background-color: #bdb9b9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
   
  .colmd6 {    
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(117, 116, 116, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .colmd6 img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease-out;
  }
  
  .colmd6:hover {
    box-shadow: 0 8px 16px rgba(146, 145, 145, 0.2);
  }