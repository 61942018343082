
/* Container styling */
.signup-container {
  color: #0a0a0a; /* Dark Grayish Blue Text Color */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;  

}

/* Heading styling */
.signup-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #3498db; /* Blue Heading Color */
}

/* Form styling */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Label styling */
label {
  margin-top: 10px;
  color: #555; /* Dark Gray Label Color */
}

/* Input and select styling */
/* Input and select styling */
input,
select {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}


/* Button styling */
button {
  background-color: #3498db; /* Blue Button Color */
  color: #fff; /* White Text Color */
  padding: 12px 24px;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  background-color: #2980b9; /* Darker Blue Hover Color */
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Error message styling */
.error-message {
  color: #e74c3c; /* Red Error Message Color */
  margin-top: 10px;
  font-size: 14px;
}
