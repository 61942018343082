.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; /* Adjust the gap between items */
  }
  
  .card-wrap {
    margin: 10px;
  }
  
  .card {
    margin-bottom: 20px; /* Adjust the margin between cards */
  }
  