/* Apply base styles */
canvas {
  display: block;
  margin: auto;
}

/* Media query for smaller screens */
@media only screen and (max-width: 550px) {
  canvas {
    width: 80%; /* Adjust the percentage as needed */
    height: auto;
  }
}

/* Media query for medium-sized screens */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  canvas {
    width: 60%; /* Adjust the percentage as needed */
    height: auto;
  }
}

/* Media query for larger screens */
@media only screen and (min-width: 1025px) {
  canvas {
    width: 50%; /* Adjust the percentage as needed */
    height: auto;
  }
}
