
  
  .user-info-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .user-info-table th, .user-info-table td {
    border: 1px solid #1d1c1c;
    padding: 8px;
    text-align: left;
  }
  
  .user-info-table th {
    background-color: #030303;
  }
  
  .user-info-table td {
    background-color: #ffffff;
  }
  
  .user-info-table tbody tr:hover {
    background-color: #ffffff;
  }
  .all-pdf{
    background-color: #ffffff;

  }