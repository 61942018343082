/* Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(128, 128, 128);

}

.login-form-container {
    background-color: white;
    width: 40%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.user-box {
    margin-bottom: 20px;
}

/* Customize the button style */
button {
    text-transform: uppercase;
    font-weight: bold;
}

.login-type {
    margin-top: 20px;
    text-align: center;
}

/* Style for Google sign-in image */
.login-type img {
    cursor: pointer;
    max-width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
        align-items: center;
    }

    .login-form-container {
        width: 100%;
        max-width: 400px;
    }
}
