  .centered-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .background-button.active {
    background-color: var(--button-active-bg);
  }

  .background-button {
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
    border-radius: 50%;
    background-color: var(--button-bg);
    border: none;
    cursor: pointer;
    padding: 0.5rem;
  }

  :root {
    --main-bg-color: rgba(255, 255, 255, 0.9);
    --text-color: #555;
    --text-dark: #777;
    --button-bg: #ddd;
    --button-active-bg: #999;
  }

  .text-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: var(--main-bg-color);
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .text-container h1 {
    font-size: 2.5rem;
    color: var(--text-color);
  }

  .text-container p {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--text-dark);
  }

  .friendly-message:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    color: rgb(97, 218, 251);
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
  }

  .solution-wrapper {
    height: auto;
    position: relative;
  }

  .solution-title {
    position: absolute;
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 36px;
    color: #297fb9;
    text-shadow: 2px 2px 4px rgba(1, 1, 1, 1.5);
  }

  .friendly-message {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    width: 250px;
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  }

  .solution-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 1s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 1s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }




  /* Media query for smaller screens (e.g., phones) */
  @media only screen and (max-width: 750px) {
    /* Adjust styles for smaller screens */

    /* Centered box height */

    /* Text container padding */
    .text-container {
      padding: 5px;
    }

    /* Background button size */
    .background-button {
      width: 1.2rem;
      height: 1.2rem;
    }



    /* Solution title font size */
    .solution-title {    color: #297fb9;

      font-size: 20px;
    }

    .buttons-container {
      padding: 5px;
    }
    :root {
      --main-bg-color: rgba(255, 255, 255, 0.9);
      --text-color: #555;
      --text-dark: #777;
      --button-bg: #dddddd00;
      --button-active-bg: #99999900;
    }
  
  }